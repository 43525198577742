import * as React from "react";
import { graphql } from "gatsby";

import SEO from "../components/template/seo";
import PageHeader from "../components/template/page-header";
import ContentBlocks from "../components/content-blocks/content-blocks";

const Page = ({ data }) => {
  const { wpPage: page } = data;

  return (
    <>
      <SEO data={page.seo} />

      <PageHeader pageHeader={page.acfPageHeader} />
      <div id="content"></div>

      {page.acfContentBlocks && (
        <ContentBlocks blocks={page.acfContentBlocks.contentBlocks} />
      )}
    </>
  );
};

export default Page;

export const pageQuery = graphql`
  query page($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      seo {
        ...SEO
      }
      acfContentBlocks {
        ...ContentBlocksPage
      }
      acfPageHeader {
        ...PageHeader
      }
    }
  }
`;
