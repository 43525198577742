import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { BgImage } from "../../helpers/bg-image";
import { ReactSVG } from "react-svg";
import { useIsBlockVisible, useIsMobile } from "../../helpers/custom-hooks";
import { parseContent } from "../../helpers/utilities";

import {
  moveGradient,
  arrowBounce,
  animateArrow,
} from "../../helpers/keyframes";

const HeaderWithBackgroundImage = styled(BgImage)`
  background-position: right;
  position: relative;
  z-index: 98;
  background-color: ${(props) => props.styles.backgroundColour};
  color: #fff;
  height: 75vh;
  min-height: 500px;
  max-height: 1200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const HeaderWithoutBackground = styled.div`
  background-color: ${(props) => props.styles.backgroundColour};
  color: #fff;
  position: relative;
`;

const Text = styled.div`
  background: linear-gradient(
    45deg,
    rgba(28, 142, 206, 0.8),
    rgba(195, 210, 0, 0.8)
  );
  background-size: 130% 130%;
  background-position: center;
  width: 66.666666%;
  padding-top: 40px;
  padding-bottom: 40px;
  animation: ${moveGradient} 12s ease infinite;
  position: relative;
  transition: all ease-in-out 0.5s;

  &:hover {
    background: linear-gradient(
      45deg,
      rgba(28, 142, 206, 0.9),
      rgba(195, 210, 0, 0.9)
    );
  }

  @media (max-width: 767px) {
    width: 90%;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 5%;
  }
`;

const Title = styled.h1`
  width: 90%;
  margin-bottom: 0.25em;
  font-size: 5rem;
  line-height: 1;

  @media (max-width: 767px) {
    font-size: 3.75rem;
  }
`;

const SubTitle = styled.p`
  width: 90%;
  max-width: 570px;
  font-size: 1.5rem;
  line-height: 1.25;
`;

const Arrow = styled.a`
  margin: 20px 0 0 66.66667%;
  transform: translateX(-20px);

  svg {
    width: 20px;
    height: 38px;
  }

  .arrow {
    fill: #fff;
  }

  &.animate {
    animation: ${arrowBounce} 0.75s ease 3;
  }

  @media (max-width: 767px) {
    margin-left: 90%;
  }
`;

const Header = ({ pageHeader, children }) => {
  if (pageHeader.headerBackgroundImage) {
    return (
      <HeaderWithBackgroundImage
        Tag="div"
        fluid={
          pageHeader.headerBackgroundImage.localFile.childImageSharp
            .gatsbyImageData
        }
        styles={{
          backgroundColour: pageHeader.headerBackgroundColour,
        }}
      >
        {children}
      </HeaderWithBackgroundImage>
    );
  }

  return (
    <HeaderWithoutBackground
      styles={{
        backgroundColour: pageHeader.headerBackgroundColour,
      }}
    >
      {children}
    </HeaderWithoutBackground>
  );
};

const PageHeader = ({ pageHeader }) => {
  const data = useStaticQuery(graphql`
    query {
      arrow: file(relativePath: { eq: "arrow.svg" }) {
        publicURL
      }
    }
  `);
  const isBlockVisible = useIsBlockVisible(pageHeader.headerDisplayDisplay);
  const isMobile = useIsMobile();

  if (isBlockVisible) {
    return (
      <Header pageHeader={pageHeader}>
        <Text className="container-left">
          <Title>{pageHeader.headerTitle}</Title>
          <SubTitle>
            {pageHeader.headerText && parseContent(pageHeader.headerText)}
          </SubTitle>
        </Text>
        <Arrow
          href="#content"
          title="Skip to content"
          aria-label="Skip to content"
          ref={(ref) => {
            animateArrow(ref);
          }}
        >
          <ReactSVG src={data.arrow.publicURL} />
        </Arrow>
      </Header>
    );
  }

  return null;
};

export default PageHeader;

export const query = graphql`
  fragment PageHeader on WpPage_Acfpageheader {
    headerBackgroundColour
    headerBackgroundImage {
      localFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 1920, quality: 80)
        }
      }
    }
    headerDisplayDisplay
    headerText
    headerTitle
  }

  fragment PostHeader on WpPost_Acfpageheader {
    headerBackgroundColour
    headerBackgroundImage {
      localFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 1920, quality: 80)
        }
      }
    }
    headerDisplayDisplay
    headerText
    headerTitle
  }
`;
