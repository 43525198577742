import { keyframes } from "styled-components";

export const moveGradient = keyframes`
	0% {
		background-position: 100% 0%;
	}

	50% {
		background-position: 0% 100%;
	}

	100% {
		background-position: 100% 0%;
	}
`;

export const arrowBounce = keyframes`
	0% {
		transform: translateY(0) translateX(-20px);
	}

	50% {
		transform: translateY(8px) translateX(-20px);
	}

	100% {
		transform: translateY(0) translateX(-20px);
	}
`;

export const animateArrow = (arrow?: HTMLElement) => {
  if ("undefined" !== typeof window) {
    window.setTimeout(() => {
      if (arrow) {
        arrow.classList.add("animate");
      }
    }, 2000);
  }
};
